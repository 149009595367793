const Web3 = require('web3');
const parseEnode = require('./key-parser').parseEnode;

/**
* Checks if an enode string is a valid String
*
* @method isValidEnode
* @param enodeUrl {String} the masternode enode string
* @protected
* @return {Boolean} whether the enode string is valid
* @since 0.4.0
*/
const validEnode = enodeUrl => {
    const parsed = parseEnode(enodeUrl);
    return !!(parsed && parsed.masternodeAddress && Web3.utils.isAddress(parsed.masternodeAddress));
};

module.exports = {
    validEnode
};
