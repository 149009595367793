import Web3 from 'web3';
import energiExtension from '@energi/web3-ext';
import {createEvent, createStore} from 'effector';
import {later} from 'itsa-utils';
import networkOptions from '../lib/constants/network-info';
import blockPolling from './block-polling';
import masternodeOwner from './masternode-owner';

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

const $lastBlock = blockPolling.$lastBlock;

const TIMEOUT = 240000; // 4 minutes
let timer, web3, initialAnnouncement;

const $txOwnerAddress = createStore('');
const on = {
    submitTx: createEvent(),
    clear: createEvent()
};

web3 = new Web3(networkOptions.networkAddress);
energiExtension.extend(web3);

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

/**
* Retrieves masternode info from the network.
* if there is a change in masternodeInfo regarding from when polling sterted,
* it will cancel further polling.
*
* @method fetchMasternodeInfo
* @protected
* @since 0.4.0
*/
const fetchMasternodeInfo = async () => {
    const masternodeInfo = await web3.masternode.masternodeInfo($txOwnerAddress.getState()), // lookup by masternode address
        announcement = !!masternodeInfo;
    if (announcement !== initialAnnouncement) {
        timer && timer.cancel();
        on.clear();
    }
};

/**
* Initiates the polling process for the specified address.
* Polling will stop if there is a change in masternodeInfo or after 4 minutes timeout.
*
* @method startPolling
* @param ownerAddress {String} masternode owner address
* @protected
* @since 0.4.0
*/
const startPolling = async ownerAddress => {
    let masternodeInfo;

    timer && timer.cancel();
    masternodeInfo = await web3.masternode.masternodeInfo(ownerAddress), // lookup by masternode address
    initialAnnouncement = !!masternodeInfo;
    $txOwnerAddress.setState(ownerAddress);

    timer = later(on.clear, TIMEOUT);
};

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

$txOwnerAddress.on(on.submitTx, (state, address) => {
    if (Web3.utils.isAddress(address)) {
        startPolling(address);
    }
    return address;
});

$txOwnerAddress.on(on.clear, () => {
    // refresh the masternode owner info:
    masternodeOwner.run.updateBalance($txOwnerAddress);
    $txOwnerAddress.setState('');
});

$lastBlock.watch(() => {
    // new block
    $txOwnerAddress.getState() && fetchMasternodeInfo();
});

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

/**
* @property masternodeListing
*     @property {String} txOwnerAddress the masternode Owner address that initiated the announce or denounce transaction
*     @property {Object} masternodeListing.on
*         @property {Function} masternodeListing.on.submitTx sets $txOwnerAddress and initiates the polling process
*         @property {Function} masternodeListing.on.clear erases $txOwnerAddress and halts the polling process
* @since 0.4.0
*/
const masternodeOperationTx = {
    $txOwnerAddress,
    on
};

export default masternodeOperationTx;
